import { createTheme } from '@mui/material/styles';
export const breakpoints = {
  xs: 0,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1280,
};
export const primaryColor = '#C93733';

export const theme = createTheme({
  breakpoints: {
    values: breakpoints,
  },
  palette: {
    primary: {
      main: primaryColor,
    },
  },
});
