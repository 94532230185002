import { useAuthContext } from 'Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { removeAuthorization } from 'Helper/api_helper';

export const useLogout = () => {
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem('token');
    dispatch({ type: 'LOGOUT' });
    navigate('/');
    removeAuthorization();
  };
  return { logout };
};
